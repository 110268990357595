/**
Utility Classes
 */

.divider{
    height: 1px;
    width: 100%;
    border-top: 1px solid $divider;
    margin: 25px 0;

    &--small{
        @extend .divider;
        margin: 10px 0;
    }
    &--hidden{
        @extend .divider;
        border: none;
    }
}

.spacer{
    @extend .divider;
    margin: 20px 0;
    padding: 0;
}

.fill-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.split{
    @extend .col-xs-6;
    padding: 0;
    width: 50%;
    text-align: left;
    @media(min-width: $screen-sm-min) {
        padding: 0;
        width: 100%;
        text-align: center;
    }
}

.full {
    @extend .col-xs-12;
    padding: 0;
}

.info-box-formatting{
    p{
        &:first-of-type{
            font-weight: 600;
        }
    }
}
.development-template-default {
    .footer {
        &__top {
            background-color: $white;
            @media(max-width: 767px) {
            border-bottom: none !important;
            }
            p{
                color: $black;
            }
            h6 {
                color: $black;
                a {
                    color: $font-primary !important;
                }
            }
            .signup-text {
                color: $font-primary;
            }

            #mc_embed_signup{
                clear:left;
            }

            .helper_text{
                background-color: transparent !important
            }

            label{
                color: $brand-primary;
            }

            .indicates-required{
                width: fit-content;
                color: $brand-primary;
                float: right;
            }

            #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
                background-color: transparent;
            }

            .mc-field-group {
                width:100% !important;
                margin: 0;
            }
        }
        .footer__content {
            .social-container{
                a {
                    color: $white !important;
                    @media(min-width: $screen-sm-min) {
                        color: $font-primary !important;
                    }
                }
            }
        }
    }
    .footer__info {
        a {
            color: $font-primary !important;
        }
    }
}

.footer {

    &__top {
        background-color: $white;
        padding: 50px 0;
        text-align: left;

        @media(min-width: $screen-sm-min) {
            background-color: $background-main;
            padding: 50px 0;
            text-align: center;
        }

        #mc_embed_signup{
            clear:left;
        }

        .helper_text{
            background-color: transparent !important
        }

        label{
            color: $font-primary;
            @media(min-width: $screen-sm-min) {
                color: white;
            }
        }

        .indicates-required{
            width: fit-content;
            color: $font-primary;
            float: right;
            @media(min-width: $screen-sm-min) {
                color: white;
            }
        }

        #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
            background-color: transparent;
        }

        .mc-field-group {
            width:100% !important;
            margin: 0;
        }

        .form-control {
            width: 100%;
            margin-right: 0px;
            padding: 20px 20px;
            height: auto;
            border-radius: 8px;
            margin-bottom: 15px;
            font-size: 19px;
            @media(min-width: $screen-sm-min) {
                float: left;
                width: 65%;
                border-radius: 8px;
                min-height: 50px;
                margin-right: 10px;
                padding: 10px 20px;
            }
        }

        .btn {
            width: 100%;
            padding: 10px 20px;
            height: auto;
            border-radius: 8px;
            font-weight: 800;
            font-size: 23px;
            box-shadow: 0px 3px 10px #00000029;
            border: transparent;
            @media(min-width: $screen-sm-min) {
                float: left;
                width: 30%;
                border-radius: 8px;
                padding: 6px 20px;
                font-size: 18px;
                font-weight: 600;
                min-height: 50px;
            }
        }

        h6 {
            color: $black;
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 20px;
            font-size: 15px;
            @media(min-width: $screen-sm-min) {
                color: $white;
                width: 100%;
                float: left;
                margin-top: 5px;
                font-size: 15px;
            }
            a {
                color: $font-primary;
                 font-weight: 900;
                @media(min-width: $screen-sm-min) {
                    color: $white;
                    font-weight: 900;
                }
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 17px;
            color: $font-primary;
            @media(min-width: $screen-sm-min) {
                font-size: 18px;
                font-weight: 400;
                color: $white;
            }
        }
    }
    .signup-text {
        color: $font-primary;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 20px;
        font-size: 18px;
        @media(min-width: $screen-sm-min) {
            color: $white;
            text-transform: uppercase;
            font-weight:900;
            font-size: 18px;
        }
    }

    background-color: $white;

    .footer__content {
        padding: 20px 0 0;
        @media(min-width: $screen-sm-min) {
            padding: 50px 0 0;
        }
        .bk-to-top{
            width:100%;
            text-align:right;
            overflow:auto;

            .to-top-text{
                text-transform:uppercase;
                font-weight:400;
                font-size:16px;
                svg{
                    margin-top:20px;
                    padding-top:10px;
                    margin-left:10px;
                }
            }
            @media(max-width:992px){
                text-align:center;
            }

            &:hover{
                cursor:pointer;
            }
        }

        p {
            font-weight: normal;
            font-size: 17px;
            line-height: 24px;
            margin-bottom: 20px;
            text-align: center;
            color: $brand-primary;
            @media(min-width: $screen-sm-min) {
                font-size: 20px;
            }
        }

        .btn {
            float: right;
        }

        .logo {
            margin: 0 auto;
            max-width: 150px;
            display: block;
            margin-bottom: 20px;
            @media(min-width: $screen-md-min) {

            }
        }

        @media(min-width: $screen-lg-min){
            .col-lg-4{
                &.border {
                    border-right: 1px solid $divider;
                }
            }
        }

        .social-container {
            text-align: center;
            background-color: $brand-primary;
            padding: 25px 0px !important;
            @media(min-width: $screen-sm-min){
                padding-top: 25px;
                text-align: left;
                background-color:$white;
            }

            a {
                top: 0;
                position: relative;
                transition: all 0.8s ease;
                font-size: 2em;
                display: inline-block;
                margin: 0 25px;
                color: $white;
                @media(min-width: $screen-sm-min){
                    color: $brand-primary;
                    transition: all 0.8s ease;
                    display: inline-block;
                    margin: 0 10px;
                }
                &:hover {
                    top: -4px;
                }
            }
        }
    }
}


.footer__navigation {
    a {
        margin-right: 45px;
        display: inline-block;
        width: fit-content;
        margin-bottom: 8px;

        @media(min-width: $screen-sm-min){
            margin-right: 0;
            display: block;
            width: 50%;
            float: left;
        }
    }
}

.footer__info {
    p {
        text-transform: uppercase;
        margin: 0;
        font-size: 12px;
    }
    a {
        font-size: 12px;
        @media(min-width: $screen-sm-min) {
            margin-right: 7px;
            margin-left: 0px;
            text-transform: none;
            font-size: 18px;
            text-align: center;
        }
        @media(max-width: 992px) {
            font-size: 16px;
        }

    }
    &--links {
			border-bottom: none;
            padding: 10px 0 15px;
            text-align: center;
        a {
            border: 1px solid $btn-color;
            text-align: center;
            margin: 5px 5px;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 4px;
            &:hover {
                text-decoration: none;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &::after {
               // display: none;
                @media(min-width: $screen-sm-min) {
                    // content: " ";
                    padding: 0 12px 0 18px;
                   // display: inline list-item;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f111";
                    font-size: 7px;
                    vertical-align: middle;
                }
            }
            @media(min-width: $screen-sm-min) {
                border: 1px solid transparent;
                margin: 0;
                padding: 5px 0px;
            }
        }
        span {
            display: none;
            @media(min-width: $screen-sm-min) {
                display: inline list-item;
            }
        }
        span:last-child {
            display: none;
        }

        @media(min-width: $screen-md-min) {
            text-align: center;
        }
		@media(max-width: $screen-xs-max) {
			border-bottom: 1px solid #c5c2c3;
			padding:10px 0;
		}
    }
	@media(max-width: $screen-xs-max) {
		text-align:center;
	}
}

.footer__blue2 {
    display: inline-block;
    height: 60px;
    font-size: 12px;
    line-height: 48px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $font-body;
    color: $font-primary;

    .blue2-footer-logo {
        height: 30px;
        width: auto;
        margin-left: 0.3rem;
        vertical-align: top;
		@media(min-width: $screen-md-min) {
			height: 28px;
		}
    }

    @media(min-width: $screen-md-min) {
        float: right;
		margin-top: 20px;
		font-size: 10px;
    }
}


.mobile-show{display: none;}
@media(max-width: 767px) {
.mobile-show{
    display: block;
}
.mobile-hide{
    display: none;
}
}

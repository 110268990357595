/* Single Development */
.development-template-default {
    background-color: $white !important;
    .development-feature {
        &__heading {
            h1 {
                color: $colorproject-1;
                font-size: 24px;
                @media(min-width: $screen-sm-min) {
                    font-size: 36px;
                }
                &:after {
                }
            }
            h2 {
                color: $colorproject-1;
                clear: both;
            }
        }
        &__content {
            p { color: $colorproject-1; }
            img {
                border-radius: 8px 8px;
            }
        }
        &__image {
                img {
                    border-radius: 8px 8px 0 0;
                }
        }
    }
}

.development-feature {
        &__tabs {
            @extend .tabbed-navigation;
        }

        &__heading {
            h2 {
                font-family: $font-navigation;
                margin: 5px 0;
                font-size: 16px;
                @media(min-width: $screen-sm-min) {
                    font-size: 20px;
                }
            }
        }

        &__content {
            margin: 15px 0;
        }

        &__links {
            margin-bottom: 10px;
        }

        &__image {
            .swiper-slide {
                height: 100%;
                width: 100%;
                img {
                    height: auto;
                    width: 100%;
                    display: block;
                    margin: auto 0;
                }
            }
        }

        .fade {
            transition: opacity 0.8s ease;
        }
    }

.development-properties {
    .single-property {
        font-weight: 500;
        display: inline-block;
        width: 100%;
        padding: 20px 15px;
        border: 1px solid black;
        text-transform: uppercase;
        font-family: $font-navigation;
        margin-bottom: 25px;

        @media(min-width: $screen-sm-min){
            padding: 15px;
            height: 110px;
        }

        a {
            text-decoration: none;
        }

        &.inactive {
            background: $brand-gray;
        }

        &:hover {
            &:not(.inactive) {
                background: #919191;
            }
        }

        p {
            margin: 4px 0;
        }

        &__heading {
            font-size: 18px;
            @media(min-width: $screen-sm-min) {
                font-size: 16px;
            }
        }
        &__rooms {
        }

        a {
            font-weight: 500;
        }
    }
}

/* Single Property */
.property-feature {
    &__image {
        @extend .feature__image;
    }
    &__heading {
        @extend .feature__heading;
    }
    &__info {
        @extend .feature__info;
    }

    &--large {
        @extend .property-feature !optional;
        margin-bottom: 30vh;
        @media(min-width: $screen-sm-min) {
            margin-bottom: 25vh;
        }
        .property-feature__image {
            box-shadow: 0 200px 100px -100px rgba(0,0,0,.3) inset;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 95vh;
            z-index: -1;
            &:after{
                content: '';
                display: block;
                position: absolute;
                top:0;
                bottom:0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,.15);
            }
        }

        .property-feature__heading {
            margin-top: 30vh;
            color: #fff;
            display: block;
            width: 100%;
            font-size: 42px;

            @media(min-width: $screen-sm-min) {
                margin-top: 50vh;
            }
            @media(min-width: $screen-md-min) {
                font-size: 48px;
            }
        }
        .property-feature__info {
            color: #fff;
            float: left;
        }
    }
}

.property-details {
    &__tabs {
        @extend .tabbed-navigation;
        text-align: right;
    }

    &__room-tabs {

    }

    &__content {
        p.heading {
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    &__room {
        background: $brand-gray;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        p:not(.heading) {
            margin: 0;
        }

        &:last-of-type {
            margin-bottom: 20px;
        }
    }

    &__links {

    }

    &__plan {
        .plan-container {
            position: relative;
            margin: 0 auto;
            width: fit-content;

            a {
                text-transform: uppercase;
            }

            .download-link {
                float: right;
            }
        }

        .hotspot {
            position: absolute;
            width: 36px;
            height: 36px;
            background: $brand-primary;
            opacity: 0.9;
            transform: translate(-18px, -18px);
            border-radius: 25px;
            animation: pulse 3s infinite;
        }

        @keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 rgba(96, 86, 85, 0.8);
            }
            70% {
                box-shadow: 0 0 0 15px rgba(96, 86, 85, 0);
            }
            100% {
                box-shadow: 0 0 0 0 rgba(96, 86, 85, 0);
            }
        }
    }
}

/* Contact Template */
.contact__details {
    h1 {
        margin-bottom: 10px;
    }
    p {
        font-size: 20px;
        font-weight: 600;
    }
}
.address {
	padding-left:10px;
    h1, p {
        font-weight:600;
        margin-bottom:0;
    }
    h1 {
        padding-bottom:15px;
    }

}
.tel {
    padding:25px 0;
	padding-left:10px;
}
/* About Template */
.about-feature {
    &__image {
        margin-bottom: 15px;
    }

    &__heading {
        @extend .feature__heading;
    }

    &__content {

    }
}

/* News blog posts */
.news__image{
	margin-bottom:20px;
}

.projects {
	.contain {
		.contain{
			padding:0px;
		}
	}
}

.section-video {
    &__info {
        h1 {
            color: $colorproject-1;
            font-size: 24px;

            &:after {
            }

            @media(min-width: $screen-sm-min) {
                font-size: 36px;
                color: $colorproject-1;
            }
        }

        h2 {
                margin: 5px 0 15px;
                font-size: 16px;
                color: $colorproject-1;
                @media(min-width: $screen-sm-min) {
                    font-size: 20px;
                    color: $colorproject-1;
                }
        }

        p {
            color: $colorproject-1;
            font-weight: 500 !important;
            line-height: 24px;
            @media(min-width: $screen-sm-min) {
                font-weight: 500 !important;
            }
        }
    }
}




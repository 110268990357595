

.site-plan-wrapper .hotspot-wrapper.hotspot--legend .hotspot {
    height: 1.5em;
    width: 1.5em;
}

.site-plan-wrapper .hotspot-wrapper .hotspot {
    border:1px solid #ffffff;
}

@media (min-width: 768px){
    .site-plan-wrapper .hotspot-wrapper .hotspot {
        height: 3em;
        width: 3em;
        border:1px solid #ffffff;
        transform: translate(-1.5em,-1.5em);
    }
    .site-plan-wrapper .hotspot-wrapper .hotspot .number {
        font-size: 1.2rem;
    }
}

.news-panel-left{
    display: flex;
    flex-direction: column;
}

.news-panel{
    display: flex;
}

.news-panel-left .split {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.news-panel-left .split a{
    color: darkblue;
}

#comments{
    display: none;
}

.single-post .entry-content .contain .row .col-md-8{
    display: flex;
    flex-direction: column;
}

.wpcf7 form{
    display: flex;
    flex-direction: column;
}


.section-map__info {
    @media (max-width: 998px){
        width: calc(100% - 50px);
        margin: auto;
        padding-left: 2.5rem;
        max-width: 100%;
    }
}
.section-map .address{
    @media (max-width: 768px){
        padding-top: 2rem
    }
}

.wpcf7-form p {
    @media (max-width: 768px){
        width: 100% !important;
        span{
            padding-left: 0.5rem;
        }
    }
}

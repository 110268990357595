body {
    font-family:"nunito-sans", sans-serif;
    color: $font-primary;
    background-color: $white;
}


#harbyn-home {
    color: $font-primary;
    background-color: $white !important;
    @media(min-width: $screen-sm-min) {
        background-color: $background-main !important;
    }
    .grid-panel {
        &__info {
            h2 {
                color: $font-primary;
                @media(min-width: $screen-sm-min) {
                    color: $white;
                }
            }
        }
        h2 {
            color: $font-primary;
            @media(min-width: $screen-sm-min) {
                color: $white;
            }
        }
        p {
            color: $font-primary;
            @media(min-width: $screen-sm-min) {
                color: $white;
            }
        }
    }
    .nav-primary, .nav-primary-left {
        position: initial;
        margin-top: 0px;
        text-align: center;

        @media(min-width: $screen-sm-min) {
            display: flex;
            align-items: center;
        }
        ul {
            flex: content;
            text-align: center;
            @media(min-width: $screen-sm-min) {
                text-align: right;
                min-height: 120px;
                align-items: center;
                display: flex;
            }
        }
        li {
            display: inline-block;
            position: initial;
            &:hover, &.active {
                a {
                    cursor: pointer;
                    background: transparent;
                }
            }

            a {
                font-weight: 600;
                font-size: 19px;
                font-size: 13px;
                line-height: 26px;
                padding: 10px 12px;
                text-transform: uppercase;
                transition: all 0.4s ease;
                &.mixitup-control-active {
                    background: #eee;
                }
                color: $white;
                z-index: 200;
                @media(min-width: $screen-sm-min) {
                    font-size: 19px;
                }
                @media(max-width: 392px) {
                    font-size: 12px;
                    padding: 10px 3px;
                }
            }
        }

        .menu-item-has-children {

            .sub-menu {
                pointer-events: none;
                //display: none;
                opacity: 0;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 100;
                top: -60px;
                padding-top: 228px;
                background: rgba(255, 255, 255, 0.95);
                height: 120vh;

                @media(max-width:768px){
                    max-width: 100%;
                    height:auto;
                    top:-20px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #c5c2c3;
                }

                @media(max-width:610px){
                    padding-top: 300px;
                }

                li {
                    display: block;
                    a {
                        @extend .fill-image;

                        display: block;
                        width: 100%;
                        height: 350px;
                        max-width: 350px;
                        text-align: left;
                        padding-top: 60%;
                        text-decoration: none;
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 20px;
                        margin-bottom: 40px;

                        @media(max-width:768px) {
                            max-width: 100%;
                            height: auto;
                        }

                        @media(min-width: $screen-sm-min){
                            padding-top: 75%;
                        }
                        @media(min-width: $screen-md-min){
                            padding-top: 85%;
                        }
                        @media(min-width: $screen-lg-min){
                            padding-top: 70%;
                        }

                        .title{
                            font-family: $font-headings;
                            color: #000;
                            width: 100%;
                            display: block;
                            position: absolute;
                            bottom: 0;
                            font-size: 20px;
                        }

                        .sub-title{
                            color: #fff;
                            font-family: $font-navigation;
                            display: block;
                        }

                        &:hover{
                            transition: transform 0.8s ease;
                        }
                    }

                    &:last-of-type {
                        a {
                            //margin-bottom: 0;
                        }
                    }
                }
            }

            &:hover, &.open {
                .sub-menu {
                    pointer-events: all;
                    display: block;
                    transition: opacity 0.6s ease;
                    opacity: 1;
                }
            }
        }
    }
}

/* Single Property Menu */
.single-property {
    .header--large{
        .nav-primary-left, .nav-primary {
            li {
                a {
                    color: $white;
                    &:hover {
                        color: $brand-primary !important;
                    }
                }
            }
        }
    }
}

.page {
    .nav-primary-left, .nav-primary {
        li {
            a {
                color: $black;
            }
        }
    }
}

#site-plan {
    .site-plan-wrapper {
        .description {
            max-width: 100%;
            text-align: left;
            p {
                color: $black !important;
                @media(min-width: $screen-sm-min) {
                }
            }
        }
    }
}

.home  {
    position: relative;
    &::before {
        content: " ";
        background-color: $brand-primary;
        max-width: 100%;
        position: relative;
        display: block;
        height: 350px;
        padding-top: 10px;
        @media(min-width: $screen-sm-min) {
            border-top: none;
            max-width: 100%;
            position: none ;
            display: block;
            height: 0px;
            padding-top: 0px;
        }
    }
    .header{
        padding-top: 10px;
        margin-top: -350px;
        margin-bottom: 25px;
        @media(min-width: $screen-sm-min) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

h1 {
    margin: 0;
    font-weight: 500;
}

h1, h2 {
    font-family: $font-headings;
}

h3, h4, h5, h6 {
    font-family: $font-headings;
}

h2 {
    margin: 10px 0;
    font-size: 23px;
}

p {
    color: $brand-primary;
    font-family: $font-body;
    @media(min-width: $screen-sm-min) {
        color: $black;
    }
}

a {
    color: $font-primary;
    font-family: $font-navigation;
    font-weight: 600;
    &:hover{
        text-decoration: none;
    }
}


blockquote{
    font-family: $font-body;
    font-size:14px;
}

.page-title {
    width: calc(100% - 50px);
    margin: 0 auto;
    max-width: 1700px;
    p {
        color: $brand-primary;
        font-family: $font-body;
        @media(min-width: $screen-sm-min) {
            color: $black;
        }
    }
}

.contain {
    @extend .container;
    padding: 0 30px;

    @media(min-width: $screen-sm-min) {
        // width: 720px;
        // width: 100%;
        // max-width: 100%;
        // padding: 0 60px;
        width: calc(100% - 50px);
        margin: 0 auto;
        max-width: 100%;
    }
    @media(min-width: $screen-md-min) {
        // padding: 0;
        // width: 920px;
        // width: 100%;
        // max-width: 1100px;
        // padding: 0 60px;
        width: calc(100% - 50px);
        margin: 0 auto;
        max-width: 100%;
    }
    @media(min-width: $screen-lg-min) {
        // width: 1200px;
        // width: 100%;
        // max-width: 1470px;
        // padding: 0 60px;
        width: calc(100% - 50px);
        margin: 0 auto;
        max-width: 1700px;
    }

    @media screen and (max-width: 1900px) {
        // width: 1200px;
    }

    &--page {
        @extend .contain;

        @media(min-width: $screen-sm-min) {
            padding-top: 40px;
        }
    }

    &--404 {
        @extend .contain;

        padding-top: 25px;
        padding-bottom: 25px;

        @media(min-width: $screen-sm-min) {
            padding-top: 50px;
            padding-bottom: 50px;
            width: 650px;
        }
        @media(min-width: $screen-md-min) {
            padding-top: 75px;
            padding-bottom: 75px;
        }
    }

    h1{
        margin-bottom:20px;

    }
}

.grid-row {
    @extend .row;
    @media(min-width: $screen-md-min) {
        margin: 0 -25px;
    }


    &:first-child {
        .grid-panel {
            &:not(.news-panel) {
                @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    &:nth-child(1), &:nth-child(2) {
                        width: 100%;
                    }
                    &:nth-child(3) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.grid-panel {
    overflow: hidden;
    margin-bottom: 25px;
    @media(min-width: $screen-sm-min) {
        margin-bottom: 50px;
    }
    @media(min-width: $screen-md-min) {
        padding: 0 25px;
    }

    &:hover {
        .grid-panel__feature {
            .image-wrap {
                transform: scale(1.03);
            }
        }
    }

    &__link {
        &:hover {
            cursor: pointer;
        }
    }

    &__feature {
        height: 290px;
        @media(min-width: $screen-sm-min) {
            width: 100%;
            height: 400px;
            overflow: hidden;
        }
        @media(max-width:992px) {
            height: 260px;
        }
        @media(max-width:800px) {
            height: 220px;
        }

        .image-wrap {
            @extend .fill-image;
            transition: transform 1.4s ease;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            position: relative;

            .split{
                position: absolute;
                bottom: -34px;
                right: 0;
                text-align: right;
                @media(min-width: $screen-sm-min) {
                    position: absolute;
                    bottom:0px;
                }
                .link-center {
                    position: unset;
                    margin: 0 auto;
                    text-align: right;
                    padding: 15px 0px;
                    color: $black;
                    font-size: 14px;
                    text-transform: uppercase;
                    @media(min-width: $screen-sm-min) {
                        position: relative;
                        bottom: 0;
                        margin: 0 auto;
                        display: -ms-flexbox;
                        display: flex;
                        text-align: center;
                        left: 0%;
                        background-color: $white;
                        padding: 10px 20px;
                        border-radius: 8px 8px 0 0;
                        width: auto;
                        color: $brand-primary;
                        margin-top: 0;
                        transform:translateX(0%);
                        max-width: 168px;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &__info {

        .split-title {
            text-align: left;
            width:65%;
            float: left;
            @media(min-width: $screen-sm-min) {
                text-align: center;
                width: 100%;
                float: none;
            }
            h2 {
            }
            .link-center {
                position: unset;
                margin: 0 auto;
                text-align: right;
                padding: 15px 0px;
                color: $black;
                @media(min-width: $screen-sm-min) {
                    position: relative;
                    bottom: 0;
                    margin: 0 auto;
                    display: -ms-flexbox;
                    display: flex;
                    text-align: center;
                    left: 50%;
                    background-color: $white;
                    padding: 10px 20px;
                    border-radius: 8px 8px 0 0;
                    width: auto;
                    color: $brand-primary;
                    margin-top: 0;
                    transform:translateX(-50%)
                }
            }
        }

        .description {
            p {
                font-size: 14px;
                margin-bottom: 0;
                @media(min-width: $screen-sm-min) {
                    font-size: 17px;
                    font-weight: 400;
                }
            }
        }

        h2 {
            display: inline-block;
            color: $font-primary;
            font-weight: 600;
            margin-right: 5px;
            margin-top: 14px;
            font-size: 23px;
            margin-bottom: 0px;
            @media(min-width: $screen-sm-min) {
                display: inline-block;
                font-weight: 500;
                margin-right: 5px;
                margin-top: 14px;
                font-size: 25px;
            }
            @media(max-width:640px) {
                display: block;
            }
        }
        p {
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 5px;
            @media(min-width: $screen-sm-min) {
                font-size: 14px;
                font-weight: 400;
            }
        }
        a {
            color: $white;
            margin-top:18px;
            font-size: 12px;
            text-align: right;
            display: inline-block;
            float: right;
            text-transform: uppercase;

            &:hover {
                cursor: pointer;
            }
        }
    }

    /* Widths */
    &--full_width {
        @extend .grid-panel;
        @extend .col-xs-12;
    }

    &--half_width {
        .split-title {
            text-align: left;
        }

        .link-center {
            position: unset;
            text-align: right;
            padding: 15px 0px;
            color: #0c4a78;
            @media(min-width: $screen-sm-min) {
                position: absolute;
                bottom: 50px;
                margin: 0 auto;
                display: -ms-flexbox;
                display: flex;
                text-align: center;
                left: 32%;
                background-color: $white;
                padding: 10px 20px;
                border-radius: 8px 8px 0 0;
                width: auto;
                color: $brand-primary;
                margin-top: 0;
            }
        }
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-sm-6;
    }

    &--one_third_width {
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-md-4;

    }

    &--two_third_width {
        @extend .grid-panel;
        @extend .col-xs-12;
        @extend .col-md-8;
    }
}

.project-text {
    .split {
        text-align: right !important;
        @media(min-width: $screen-sm-min) {
            text-align: left !important;
        }

        .link-center {
            position: unset;
            text-align: right;
            padding: 15px 0px;
            color: #0c4a78;

            @media(min-width: $screen-sm-min) {
                right: 0 !important;
                left: 32% !important;
                transform: translateX(0%) !important;
            }
            @media(max-width: 1199px) {
                left: 0% !important;
            }
            @media(max-width: 767px) {
                padding: 0px 0px;
            }
        }
    }
}

.feature {

    &__image {
        @extend .fill-image;
        height: 300px;
        margin-bottom: 8px;

        @media(min-width: $screen-sm-min) {
            height: 400px;
        }
        @media(min-width: $screen-md-min) {
            height: 500px;
        }

        @media(min-width: $screen-lg-min) {
            height: 650px;
        }
    }

    &__heading {
        font-weight: 600;
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            float: left;
        }
    }

    &__info {
        display: inline-block;
        font-size: 15px;
        text-transform: uppercase;
        font-family: $font-body;
        font-weight: 600;
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            float: right;
            margin: 10px 0;
        }
    }
}

.description {
    max-width: 500px;
    text-align: center;
    margin: 30px auto;
    @media(min-width: $screen-sm-min) {
        margin: 35px auto 0;
    }
    .border-top {
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        padding: 5px 0;
        width: 90px;
        text-align: center;
        margin: 0 auto;
        border-top: 1px solid #c5c2c3;
    }
    .border-bottom {
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        padding: 5px 0;
        width: 90px;
        text-align: center;
        margin: 0 auto;
        border-bottom: 1px solid #c5c2c3;
    }
}

.tabbed-navigation {
    margin-bottom: 10px;
    text-align: center;
    @media(min-width: $screen-sm-min) {
        text-align: right;
        margin-bottom: 30px;
    }

    h1{
        margin-bottom:40px;
    }

    .nav {
        li {
            a {
                font-size: 12px;
                padding: 10px 4px;
                @media(min-width: $screen-sm-min) {
                    font-size: 14px;
                    padding: 10px 15px;
                }
            }
        }
    }
}

.development-template-default {
    a {
        color: $project-menu1 !important;
    }
    .nav-project {
        text-align: center !important;
        @media(min-width: $screen-sm-min) {
            text-align: right !important;
        }
        ul {
            justify-content: flex-end;
            min-height: auto;
            @media(min-width: $screen-sm-min) {
                justify-content: flex-end;
                min-height: auto;
            }
        }
        li {
            a {
                padding: 5px 5px;
                @media(min-width: $screen-sm-min) {
                    padding: 10px 15px;
                }
            }
            &:hover, &.active {
                a {
                    cursor: pointer;
                    background: #f5f5f5 !important;
                }
            }
        }
    }
}

.nav-primary {
    @media(min-width: $screen-sm-min) {
        text-align: left !important;
    }

}
.nav-primary-left {
    display: none;

}

.nav-primary, .nav-primary-left {
    position: initial;
    margin-top: 0px;
    text-align: center;

    @media(min-width: $screen-sm-min) {
        display: flex;
        align-items: center;
    }
    ul {
        flex: content;
        text-align: center;
        @media(min-width: $screen-sm-min) {
            text-align: right;
            min-height: 120px;
            align-items: center;
            display: flex;
        }
    }
    li {
        display: inline-block;
        position: initial;
        &:hover, &.active {
            a {
                cursor: pointer;
                background: $brand-gray;
            }
        }

        a {
            font-weight: 600;
            font-size: 19px;
            font-size: 13px;
            line-height: 26px;
            padding: 10px 12px;
            text-transform: uppercase;
            transition: all 0.4s ease;
            &.mixitup-control-active {
                background: #eee;
            }
            color: $black;
            z-index: 200;
            @media(min-width: $screen-sm-min) {
                font-size: 19px;
            }
            @media(max-width: 392px) {
                font-size: 12px;
                padding: 10px 3px;
            }
        }
    }

    .menu-item-has-children {

        .sub-menu {
            pointer-events: none;
            //display: none;
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 100;
            top: -60px;
            padding-top: 228px;
            background: rgba(255, 255, 255, 0.95);
            height: 120vh;

            @media(max-width:768px){
                max-width: 100%;
                height:auto;
                top:-20px;
                padding-bottom: 30px;
                border-bottom: 1px solid #c5c2c3;
            }

            @media(max-width:610px){
                padding-top: 300px;
            }

            li {
                display: block;
                a {
                    @extend .fill-image;

                    display: block;
                    width: 100%;
                    height: 350px;
                    max-width: 350px;
                    text-align: left;
                    padding-top: 60%;
                    text-decoration: none;
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 20px;
                    margin-bottom: 40px;

                    @media(max-width:768px) {
                        max-width: 100%;
                        height: auto;
                    }

                    @media(min-width: $screen-sm-min){
                        padding-top: 75%;
                    }
                    @media(min-width: $screen-md-min){
                        padding-top: 85%;
                    }
                    @media(min-width: $screen-lg-min){
                        padding-top: 70%;
                    }

                    .title{
                        font-family: $font-headings;
                        color: #000;
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        font-size: 20px;
                    }

                    .sub-title{
                        color: #fff;
                        font-family: $font-navigation;
                        display: block;
                    }

                    &:hover{
                        transition: transform 0.8s ease;
                    }
                }

                &:last-of-type {
                    a {
                        //margin-bottom: 0;
                    }
                }
            }
        }

        &:hover, &.open {
            .sub-menu {
                pointer-events: all;
                display: block;
                transition: opacity 0.6s ease;
                opacity: 1;
            }
        }
    }
}

.nav-primary {
    justify-content: flex-start;
    @media(min-width: $screen-sm-min) {
        justify-content: flex-start;
    }
    ul {
        justify-content: flex-start;
        @media(min-width: $screen-sm-min) {
            justify-content: flex-start;
        }
    }
}

.nav-primary-left {
    justify-content: flex-end;
    @media(min-width: $screen-sm-min) {
        justify-content: flex-end;
    }
    ul {
        justify-content: flex-end;
        @media(min-width: $screen-sm-min) {
            justify-content: flex-end;
        }
    }
}

.modal {
    .modal-content {
        border-radius: 0px;

        .modal-header {
            font-size: 1.4em;
            border: none;
            padding: 15px;
        }
    }
}

#videoModal {
    .modal-dialog {
        top: 30%;
        @media(min-width: $screen-md-min) {
            top: 0;
            padding-top: 10%;
            width: 950px;
        }
        @media(min-width: $screen-lg-min) {
            padding-top: 0;
            width: 1200px;
        }
        .modal-content {
            background: transparent;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            .modal-header {
                .modal-close {
                    color: #fff;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .modal-body {
                padding: 0 15px 15px 15px;
            }
        }
    }
}

.modal-backdrop {
    &.in {
        opacity: 0.9;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    border-radius: 8px 8px;
    iframe, object embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.alert {
    background: $brand-gray;
    color: $brand-primary;
    font-family: $font-body;
    border-radius: 0;
    font-weight: 600;
}

div.wpcf7-mail-sent-ok {
    border: none;
}

.single-sidebar{
    @media(min-width: $screen-md-min) {
        border-left:1px solid #c5c2c3;
    }
    h2 {
        margin-top:0;
    }

    ul{
        padding-left:0;
        li{
            list-style-type:none;
            margin-bottom:10px;
        }
    }
}

/* Page Transition */
body {
    background-color: #fff;
    opacity: 1;
    transition: 1s opacity;
}

body.fade-out {
    opacity: 0;
    transition: none;
}

body.fade-out-anim {
    opacity: 0;
    transition: 0.5s opacity;
}

.property-details{
    .tab-pane#detail-content{
        border-top:1px solid rgba(197, 194, 195, 0.7);
        padding-top:15px;

        @media(max-width:992px){
            padding-top:0;
            border-top:0;
        }

    }
    .plan-container{
        border-top:1px solid rgba(197, 194, 195, 0.7);
        padding-top:15px;

        @media(max-width:992px){
            padding-top:0;
            border-top:0;
        }

    }

    .property-details__plan{
        border-left:1px solid rgba(197, 194, 195, 0.7);

        @media(max-width:992px){
            border-left:0;
        }
    }
}

/* News Layout */
.tabbed-navigation{
    h1{
        text-align:left;
        margin-bottom:40px;
    }
}

@media(min-width: $screen-md-min) {
    .news-panel-left {
        border-right: 1px solid #c5c2c3;
    }
}

.nav-news{
    text-align:left;
    display:none;
    @media(min-width: $screen-md-min) {
        display:block;
    }
    ul{
        padding-left:0;

        .mixitup-control-active{
            background:#eee;
        }

        li{
            &:hover{
                cursor:pointer;
            }
        }
    }
}

.other-content{
    .content-block{
        @media(max-width: $screen-sm-max) {
            margin-bottom:50px;
        }
    }

    .image-container{
        margin-bottom:50px;
        img{
            max-width:100%;
        }
    }
}

.highlight-text {
    padding: 10px 0px;
    display: inline-block;
    color: $colorproject-1;
    margin-bottom: 0;
    @media(min-width: $screen-sm-min) {
        padding: 10px 0px;
        display: inline-block;
        color: $colorproject-1;
    }
}

/* Photoswipe Overrides */
.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
    background: url(../images/default-skin.png) 0 0 no-repeat;
}

.pswp__button--arrow--left:before {
    background-position: -138px -44px !important;
}

.pswp__button--arrow--right:before {
    background-position: -94px -44px !important;
}

.nav-primary {
    .menu-news, .menu-projects{
        display: inline-block !important;

        @media(min-width: $screen-sm-min) {
            display: none !important;
        }

    }
}

.wpcf7-form{
    label{
        color: $font-primary;
    }
}

@media(max-width:992px) {
    .grid-panel--one_third_width{
        width: 33.3% !important;
    }
}
@media(max-width:767px) {
    .grid-panel--one_third_width{
        width: 100% !important;
    }
    a.mobile {
        color: $black !important;
        font-size: 14px;
        flex-wrap: wrap;
        display: flex;
    }
    .mobile-text{ width: 35% !important;}
}

.site-plan-wrapper{

    .top-wrapper{
        margin-bottom:2rem;
    }

    .hotspot-wrapper{
        position:relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .hotspot{
            position:absolute;
            height:1.5em;
            width:1.5em;
            background:green;
            transform:translate(-0.75em, -0.75em);
            border-radius:50%;
            display:flex;
            align-items: center;
            justify-content: center;

            &.sold, &.reserved{
                background:red;
            }
            &.under_offer{
                background:yellow;
            }

            .number{
                font-size:0.8em;
                color:white;
            }

            a{
                display:flex;
                align-items: center;
                justify-content: center;
                height:100%;
                width:100%;
                &:hover{
                    text-decoration: none;
                }
            }

        }
        &.hotspot--legend {
            display: flex;
            >div{
                display: flex;
                padding-right: 10px;
            }
            .hotspot{
                position: static;
                display: inline-block;
                margin-right: 5px;
                transform: none;
            }
        }
    }
}

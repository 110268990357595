

/* Section Map */
.section-map {
    width: 100%;
    position: relative;

    #map {
        width: 100%;
        height: 400px;
        @media(min-width: $screen-md-min) {
            height: 650px;
        }
    }

    .contain {
        position: relative;
        width: 100%;
        padding: 0;
        max-width: 100%;
    }

    &__info {
        @extend .info-box-formatting;
        pointer-events: auto;
        display: inline-block;
        background: #fff;
        width: 100%;
        padding: 25px 0;

        @media(min-width: $screen-md-min) {
            width: 350px;
            padding: 25px;
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translate(0, -50%);
            .btn-large {
                margin-bottom: 10px;
            }
        }

        @media(min-width: $screen-lg-min) {
            padding: 40px;
            width: 400px;
        }
    }

    .marker__image{
        display: flex;
        height: 100px;
        justify-content: center;
        align-items: center;

        img,svg{
            width:100px;
            object-fit: contain;
            height: auto;
        }
        svg{
            width: 200px;
            height: auto;
        }
    }
}

/* Section Video */
.section-video {
    &__info {
        @extend .info-box-formatting;
    }
}

/* Section Gallery */
.section-gallery {
    @media(min-width: $screen-sm-min) {
        column-count: 2;
        column-gap: 0;
    }
    @media(min-width: $screen-lg-min) {
        column-count: 3;
    }

    .single-image {
        width: 100%;
        height: auto;
        margin-bottom: 25px;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }
        @media(min-width: $screen-sm-min) {
            display: inline-block;
            margin-bottom: 30px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}

//@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//background
$background-main: #1C3348;

// Colors
$brand-primary: #0C4A78;//#605655;

// Project colors
$colorproject-1: #414141;
$project-menu1: #545454;
$border-project1: #6E2F3B;

$brand-gray: #DFDFDF;
$highlight-blue: #75a2d3;

$font-primary: $brand-primary;
$divider: #C5C2C3;
$white: #ffffff;
$black: #000000;

//Button
$btn-color: #3579B5;

$font-headings:"nunito-sans", sans-serif;
$font-body:"nunito-sans", sans-serif;
$font-navigation:"nunito-sans", sans-serif;

//animations


@keyframes slide-left-fade {
    0% {opacity:0; margin-left:40px;}
    30% {opacity:0; margin-left:40px;}
    100% {opacity:1; margin-left:0px;}
}
.development-template-default {
    .header {
        @media(max-width: 767px) {
        border-top: none !important;
        }
    }
}

.header {
    padding-top: 10px;

    @media(min-width: $screen-sm-min){
        padding: 25px 0;
    }

    .logo-container {
        text-align: center;
        @media(min-width: $screen-sm-min) {
            text-align: center;
        }
    }

    .brand {
        text-align: center;
        display: inline-block;

        img,svg {
            display: block;
            margin: 0 auto;
            max-height: 80px;
            height: auto;
            width: auto;
        }
    }

    &--large{
        @extend .header;
        .nav-primary {
            li{
                a{
                    color: #fff;
                }

                &:hover{
                    a {
                        color: $brand-primary;
                    }
                }

            }
        }

        .divider{
            border-color: #fff;
        }
    }

}
